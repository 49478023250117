<template>
<div class="app-exchanges-transaction-page">
  <div class="page__wrapper">
    <div class="page__header-actions">
      <a-button type="text" @click="toExchangesPage">
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        回到首頁
      </a-button>
    </div>

    <a-spin tip="Loading..." :spinning="spinning">
      <a-card class="transction-card">
        <div class="card-title">交易</div>

        <div class="card-body">
          <div class="body-item" :style="{ marginBottom: '24px' }">
            <a-radio-group v-model:value="transferState.type" @change="changeTransferType">
              <a-radio-button value="expenditure">支出</a-radio-button>
              <a-radio-button value="received">收入</a-radio-button>
            </a-radio-group>
          </div>

          <div class="body-item">
            <div class="item-label">選擇兌換所</div>
            <a-select
              v-model:value="transferState.toAddress" 
              :options="exOptions"
              :disabled="transferLoading"
              size="large"
              style="width:100%;"
            ></a-select>
          </div>

          <div class="body-item">
            <div class="item-label">幣別</div>
            <a-select
              v-model:value="transferState.currency" 
              :options="currencyOptions"
              :disabled="transferLoading"
              size="large"
              style="width:100%;"
            ></a-select>
          </div>
          
          <div class="body-item">
            <div class="item-label">金額</div>
            <a-input
              v-model:value="transferState.amount"
              :disabled="transferLoading"
              size="large"
            >
              <template #suffix>{{ transferState.currency }}</template>
            </a-input>

            <template v-if="transferState.currency == 't-Coin'">
              <div style="text-align:right; margin-top:12px;">
                <span style="margin-right:8px; color:#757575; font-weight:500;">我的餘額</span>
                <span style="color:#424242; font-weight:bold;">{{ currencyWalletAmount }}</span>
              </div>
            </template>
          </div>

          <div class="body-item">
            <div class="item-label">備註</div>
            <a-input
              v-model:value="transferState.note"
              :disabled="transferLoading"
              size="large"
            />
          </div>
        </div>

        <div class="body-actions">
          <a-button
            type="primary"
            size="large"
            :disabled="disabled || !validForm || transferLoading"
            @click="confirm"
            style="width:100%;"
          >確認</a-button>
        </div>
      </a-card>
    </a-spin>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import CryptoJS from 'crypto-js'
import { defineComponent, createVNode } from 'vue'
import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'

export default defineComponent({
  components: {
    ArrowLeftOutlined
  },
  setup() {
    return {}
  },
  mounted() {
    window.scrollTo(0, 0);
    this.init();
  },
  data() {
    return {
      // wallect init
      spinning: false,

      // options
      // currencyOptions: [{
      //   label: 't-Coin',
      //   value: 't-Coin'
      // }, {
      //   label: 'NTD',
      //   value: 'NTD'
      // }],
      exOptions: [],
      exIdAddrMapping: {}, // addr: user_id

      // transfer
      transferLoading: false,
      transferState: {
        type: 'expenditure',
        currency: 't-Coin',
        toAddress: '',
        amount: '',
        note: ''
      },

      // data
      exchangesData: []
    }
  },
  computed: {
    // currency options
    currencyOptions() {
      let opts = [];

      if (this.transferState.type == 'expenditure') {
        opts = [{
          label: 't-Coin',
          value: 't-Coin'
        }, {
          label: 'NTD',
          value: 'NTD'
        }]
      } else if (this.transferState.type == 'received') {
        opts = [{
          label: 'NTD',
          value: 'NTD'
        }]
      }

      return opts
    },

    // wallet
    currencyWalletAmount() {
      if (this.$store.state.wallet.amount) {
        let tcoin_amount = this.$store.state.wallet.amount / 1000000; // utcoin -> tcoin

        return tcoin_amount.toLocaleString('zh-TW', {
          currency: 'TWD'
        });
      } else {
        return 0
      }
    },
    validForm() {
      let tcoin_amount = this.$store.state.wallet.amount / 1000000;

      if (this.$store.state.wallet.address &&
        this.$store.state.wallet.amount > 0 &&
        this.transferState.toAddress &&
        this.transferState.amount > 0 &&
        tcoin_amount >= this.transferState.amount
      ) return true

      return false
    },

    // confirm button
    disabled() {
      if (!this.transferState.toAddress || !this.transferState.amount) return true
      return false
    }
  },
  methods: {
    /* router */
    toExchangesPage() {
      this.$router.push({ name: 'AppExchanges' });
    },

    /* handle transfer type */
    changeTransferType(e) {
      if (e.target.value == 'received') {
        this.transferState.currency = 'NTD';
      } else {
        this.transferState.currency = 't-Coin';
      }
    },

    /* Confirm func */
    confirm() {
      const self = this;
      Modal.confirm({
        content: `確認是否進行交易 ${self.transferState.amount} ${self.transferState.currency}`,
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          self.handleConfirm();
        },
        okText: '確認',
        cancelText: '取消',
        onCancel() {
          Modal.destroyAll();
        },
      });
    },
    async handleConfirm() {
      this.transferLoading = true;
      const hideTransferLoadingMsg = message.loading('處理轉帳中，請勿關閉頁面', 0);

      // Transfer Currency: t-Coin
      if (this.transferState.currency == 't-Coin') {
        await this.transferTcoin();
      } else if (this.transferState.currency == 'NTD') { // Transfer Currency: NTD
        await this.transferNTD();
      }

      // reset
      this.transferState = {
        type: 'expenditure',
        currency: 't-Coin',
        toAddress: '',
        amount: '',
        note: ''
      }
      await this.getWalletBalance();
      this.transferLoading = false;
      hideTransferLoadingMsg();
    },

    /* init func */
    async init() {
      this.spinning = true;

      await this.initWallet();
      await this.getExchangeAccounts();

      this.spinning = false;
    },

    /* transfer func */
    async transferTcoin() {
      // transfer in blockchain
      let utcoinAmount = this.transferState.amount * 1000000;
      const value = {
        amount: [{ amount: utcoinAmount.toString(), denom: this.$store.state.wallet.denom }],
        toAddress: this.transferState.toAddress,
        fromAddress: this.$store.state.wallet.address
      }
      
      let transactionHash = '';
      try {
        const txResult = await this.$store.dispatch('cosmos.bank.v1beta1/sendMsgSend', { value });
        
        if (txResult && !txResult.code) {
          // insert transaction
          transactionHash = txResult.transactionHash;
          await this.insertTransaction(transactionHash);
          
          message.success('Transfer Success!');
        }
      } catch (e) {
        console.error(e);
        message.error('Error: Transfer failed.');
      }
    },
    async transferNTD() {
      try {
        // insert transaction
        const transactionHash = '';
        await this.insertTransaction(transactionHash);

        message.success('Transfer Success!');
      } catch (e) {
        console.error(e);
        message.error('Error: Transfer failed.');
      }
    },
    async receivedNTD() {
      try {
        // insert transaction
        const transactionHash = '';
        await this.insertTransaction(transactionHash);

        message.success('Transfer Success!');
      } catch (e) {
        console.error(e);
        message.error('Error: Transfer failed.');
      }
    },

    /* wallet func */
    async initWallet() {
      await this.$store.dispatch('common/env/init');

      /* if imported wallet with mnemonic, unlock the wallet */
      // decrypt
      let decryptedMnemonic = CryptoJS.AES.decrypt(
        this.$store.state.auth.info.wallet.mnemonic,
        `${process.env.VUE_APP_ENCRYPT_PREFIX}${this.$store.state.auth.info.user_info.enc_k}`
      ).toString(CryptoJS.enc.Utf8);

      await this.$store.dispatch('common/wallet/createWalletWithMnemonic', {
        name: this.$store.state.auth.info.email,
        mnemonic: decryptedMnemonic,
        password: this.$store.state.auth.info.id.toString(),
        prefix: this.$store.state.common.env.addrPrefix,
      });

      this.$store.state.wallet.address = this.$store.getters['common/wallet/address'];

      // get balance
      await this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
        params: { address: this.$store.state.wallet.address },
        options: { all: true, subscribe: false },
      });
      let balance = this.$store.getters['cosmos.bank.v1beta1/getAllBalances']({
        params: { address: this.$store.state.wallet.address },
      })?.balances ?? []
      
      if (balance.length > 0) {
        this.$store.state.wallet.denom = balance[0].denom;
        this.$store.state.wallet.amount = parseInt(balance[0].amount);
      } else {
        this.$store.state.wallet.denom = 'utcoin';
        this.$store.state.wallet.amount = 0;
      }
    },
    async getWalletBalance() {
      await this.$store.dispatch('cosmos.bank.v1beta1/QueryAllBalances', {
        params: { address: this.$store.state.wallet.address },
        options: { all: true, subscribe: false },
      });
      let balance = this.$store.getters['cosmos.bank.v1beta1/getAllBalances']({
        params: { address: this.$store.state.wallet.address },
      })?.balances ?? []
      
      if (balance.length > 0) {
        this.$store.state.wallet.denom = balance[0].denom;
        this.$store.state.wallet.amount = parseInt(balance[0].amount);
      } else {
        this.$store.state.wallet.denom = 'utcoin';
        this.$store.state.wallet.amount = 0;
      }
    },

    /* api */
    async getExchangeAccounts() {
      this.loading = true;
      this.exOptions = [];

      const url = process.env.VUE_APP_API + `/users?role=3&coop_info.id=${this.$store.state.auth.info.coop_info.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);
      const resData = response.data;
      this.exchangesData = resData;

      resData.forEach(item => {
        this.exOptions.push({
          label: `${ item.ex_user_info.exchange_name }兌換所`,
          value: item.user_info.wallet_Address
        });

        this.exIdAddrMapping[item.user_info.wallet_Address] = item.id;
      });

      this.loading = false;
    },
    async insertTransaction(txHash) {
      const url = process.env.VUE_APP_API + '/admin-transactions';
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }

      const data = {
        coop_info: this.$store.state.auth.info.coop_info.id,
        from_user: '',
        to_user: '',
        amount: this.transferState.amount,
        currency: this.transferState.currency,
        tx_hash: txHash,
        tx_id: new Date().getTime().toString(),
        note: this.transferState.note
      }

      // set expenditure | received
      if (this.transferState.type == 'expenditure') {
        data.from_user = this.$store.state.auth.info.id;
        data.to_user = this.exIdAddrMapping[this.transferState.toAddress];
      } else {
        data.from_user = this.exIdAddrMapping[this.transferState.toAddress];
        data.to_user = this.$store.state.auth.info.id;
      }

      const response = await axios.post(url, data, config);
      const resData = response.data;
      console.log(resData);
    },
    async updateExchangeDebt() {
      // get to_user ex-user-infos id
      let exUserInfoId = '';
      this.exchangesData.forEach(item => {
        if (item.id == this.exIdAddrMapping[this.transferState.toAddress]) exUserInfoId = item.ex_user_info.id;
      });
      
      // setting url
      const url = process.env.VUE_APP_API + `/ex-user-infos/${exUserInfoId}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }

      // find current debt
      const response = await axios.get(url, config);
      let debt = response.data.debt;

      // update debt
      await axios.put(url, {
        debt: debt + parseInt(this.transferState.amount)
      }, config);
    }
  }
})
</script>

<style lang="scss">
.app-exchanges-transaction-page {
  padding-bottom: 40px;

  .page__wrapper {
    width: 550px;
    margin: 0 auto;
  }
}

/* ----- Transaction Card ----- */
.transction-card {
  padding: 20px 20px;
  margin-top: 20px;
  border-width: 3px;
  border-radius: 15px;
  position:relative;

  .card-title {
    color: #212121;
    font-size: 1.6rem;
    font-weight: 550;
    letter-spacing: 0.0425rem;
  }

  .card-body {
    width: 100%;
    padding: 30px 0;

    .body-item {
      margin-bottom: 20px;

      .item-label {
        color: #616161;
        font-size: 1.1rem;
        font-weight: 400;
        letter-spacing: 0.03rem;
        margin-bottom: 4px;
      }
    }
  }
}
</style>